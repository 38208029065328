import React, { useEffect, useReducer } from 'react';
import { Auth } from 'aws-amplify';

export const AuthContext = React.createContext();

const initialState = {
	loading: true,
	loggedIn: false,
	newPasswordRequired: false,
	user: null,
	isAdmin: false,
	stationId: '',
	data: null
};

const AuthReducer = (state, action) => {
	switch (action.type) {
		// not logged in
		case 'notLoggedIn':
			return { ...state, loading: false };
		// logout
		case 'logoutLoading':
			return { ...state, loading: true };
		case 'logoutSuccess':
			return { ...initialState, loading: false };
		case 'logoutError':
			return { ...state, loading: false, error: action.error };
		// login
		case 'loginLoading':
			return { ...state, loading: true, user: null, loggedIn: false, error: null, isAdmin: false };
		case 'loginSuccess':
			return {
				...state,
				loading: false,
				user: action.user,
				loggedIn: true,
				newPasswordRequired: action.newPasswordRequired || false,
				isAdmin: action.isAdmin || false,
				stationId: action.stationId || '',
				data: action.data
			};
		case 'loginError':
			return { ...state, loading: false, error: action.error };
		// change password
		case 'changePasswordLoading':
			return { ...state, loading: true, loggedIn: false, error: null, isAdmin: false, stationId: '' };
		case 'changePasswordSuccess':
			return {
				...state,
				loading: false,
				newPasswordRequired: false
			};
		case 'changePasswordError':
			return { ...state, loading: false, error: action.error };
		// Complete new password
		case 'completeNewPasswordLoading':
			return { ...state, loading: true, loggedIn: false, error: null, isAdmin: false, stationId: '' };
		case 'completeNewPasswordSuccess':
			return {
				...state,
				loading: false,
				user: action.user,
				loggedIn: true,
				newPasswordRequired: false,
				isAdmin: action.isAdmin,
				stationId: action.stationId
			};
		case 'completeNewPasswordError':
			return { ...state, loading: false, error: action.error };
		default:
			throw new Error();
	}
};

export const AuthProvider = ({ loader, children }) => {
	const [ state, dispatch ] = useReducer(AuthReducer, initialState);

	const logout = async () => {
		try {
			dispatch({ type: 'logoutLoading' });
			await Auth.signOut();
			dispatch({ type: 'logoutSuccess' });
		} catch (error) {
			console.error(error);
			dispatch({ type: 'logoutError', error: 'Uitloggen mislukt' });
		}
	};

	const login = async (usernameOrEmail, password) => {
		dispatch({ type: 'loginLoading' });
		try {
			const data = await Auth.signIn(usernameOrEmail, password);
			if (data.challengeName === 'NEW_PASSWORD_REQUIRED') {
				dispatch({
					type: 'loginSuccess',
					newPasswordRequired: data.challengeName === 'NEW_PASSWORD_REQUIRED',
					data
				});
			} else {
				const user = await Auth.currentUserInfo();
				dispatch({
					type: 'loginSuccess',
					newPasswordRequired: false,
					user,
					isAdmin: user.attributes['custom:isAdmin'] ? user.attributes['custom:isAdmin'] : false,
					stationId: user.attributes['custom:stationId'] ? user.attributes['custom:stationId'] : false
				});
			}
		} catch (error) {
			console.error(error);
			dispatch({ type: 'loginError', error: 'Inloggen mislukt' });
		}
	};

	const changePassword = async (oldPassword, newPassword) => {
		try {
			dispatch({ type: 'changePasswordLoading' });
			await Auth.changePassword(state.user, oldPassword, newPassword);
			dispatch({ type: 'changePasswordSuccess' });
		} catch (error) {
			dispatch({ type: 'changePasswordError', error: 'Wachtwoord wijzigen mislukt' });
			console.error(error);
		}
	};

	const completeNewPassword = async (password) => {
		try {
			dispatch({ type: 'completeNewPasswordLoading' });
			await Auth.completeNewPassword(state.data, password);
			const user = await Auth.currentUserInfo();
			dispatch({
				type: 'completeNewPasswordSuccess',
				user,
				isAdmin: user.attributes['custom:isAdmin'] ? user.attributes['custom:isAdmin'] : false,
				stationId: user.attributes['custom:stationId'] ? user.attributes['custom:stationId'] : false
			});
		} catch (error) {
			dispatch({ type: 'completeNewPasswordError', error: 'Nieuw wachtwoord instellen mislukt' });
			console.error(error);
		}
	};

	const forgotPassword = async (username) => {
		try {
			const data = await Auth.forgotPassword(username);
			//console.log('authContext:forgotPassword', data);
		} catch (error) {
			console.error(error);
		}
	};

	const forgotPasswordSubmit = async (username, code, newPassword) => {
		try {
			const data = await Auth.forgotPasswordSubmit(username, code, newPassword);
			//console.log('authContext:forgotPasswordSubmit', data);
		} catch (error) {
			console.error(error);
		}
	};

	useEffect(() => {
		const fetchUser = async () => {
			try {
				const data = await Auth.currentAuthenticatedUser();
				const user = await Auth.currentUserInfo();
				dispatch({
					type: 'loginSuccess',
					newPasswordRequired: false,
					user,
					data,
					isAdmin: user.attributes['custom:isAdmin'] ? user.attributes['custom:isAdmin'] : false,
					stationId: user.attributes['custom:stationId'] ? user.attributes['custom:stationId'] : false
				});
			} catch (error) {
				dispatch({ type: 'notLoggedIn' });
			}
		};
		fetchUser();
	}, []);

	if (state.loading) {
		return loader(true);
	}

	return (
		<AuthContext.Provider
			value={{
				state,
				login,
				logout,
				forgotPassword,
				forgotPasswordSubmit,
				changePassword,
				completeNewPassword
			}}
		>
			{children}
		</AuthContext.Provider>
	);
};

export const AuthConsumer = AuthContext.Consumer;
