import React, { useState } from 'react';
// import { AuthContext } from '~/components/auth/authContext';
import Api from '../api';

import gql from 'graphql-tag';

const listNewsItemsQuery = gql`
	query listNewsItems($page: Int!, $perPage: Int!, $filter: FilterFindManyNewsItemInput) {
		NewsItemPagination(page: $page, perPage: $perPage, sort: DATETIME_DESC, filter: $filter) {
			count
			items {
				_id
				title
				intro
				text
				image
				dateTime
				type
				tag
				focus
			} 
		}
	}
`;

const getDefaultNewsItemQuery = gql`
	query listNewsItems ($type: EnumNewsItemType!) {
		NewsItemPagination(page: 1, perPage: 1, filter: { focus: true, type: $type }, sort: DATETIME_DESC) {
			items {
				_id
				title
				intro
				text
				image
				dateTime
				type
				tag
				focus
			}
		}
	}
`;

const getPageNewsItemQuery = gql`
	query listNewsItems ($type: EnumNewsItemType!) {
		NewsItemPagination(page: 1, perPage: 1, filter: { type: $type }, sort: DATETIME_DESC) {
			items {
				_id
				title
				intro
				text
				image
				dateTime
				type
				tag
				focus
			}
		}
	}
`;

const getNewsItemQuery = gql`
	query getNewsItem($_id: MongoID!) {
		NewsItemById(_id: $_id) {
			_id
			title
			intro
			image
			text
			dateTime
			type
			tag
			focus
		}
	}
`;

const createNewsItemQuery = gql`
	mutation NewsItemCreateOne($record: CreateOneNewsItemInput!) {
		NewsItemCreateOne(record: $record) {
			recordId
			record {
				_id
				title
				dateTime
			}
		}
	}
`;

const updateNewsItemQuery = gql`
	mutation NewsItemUpdateById($record: UpdateByIdNewsItemInput!) {
		NewsItemUpdateById(record: $record) {
			recordId
			record {
				_id
				title
				dateTime
			}
		}
	}
`;

const removeNewsItemQuery = gql`
	mutation NewsItemRemoveById($_id: MongoID!) {
		NewsItemRemoveById(_id: $_id) {
			recordId
		}
	}
`;

export const NewsContext = React.createContext();

export const NewsProvider = ({ children }) => {
	// const { auth } = useContext(AuthContext);
	const [ currentPage, setCurrentPage ] = useState(1);
	const [ total, setTotal ] = useState(0);
	const [ pageSize, setPageSize ] = useState(10);

	const [ focusNewsItems, setFocusNewsItems ] = useState(null);
	const [ focusNewsItemsError, setFocusNewsItemsError ] = useState(null);
	const [ focusNewsItemsLoading, setFocusNewsItemsLoading ] = useState(false);

	const [ focusEventItem, setFocusEventItem ] = useState(null);
	const [ focusEventItemError, setFocusEventItemError ] = useState(null);
	const [ focusEventItemLoading, setFocusEventItemLoading ] = useState(false);

	const [ newsItem, setNewsItem ] = useState(null);
	const [ newsItemError, setNewsItemError ] = useState(null);
	const [ newsItemLoading, setNewsItemLoading ] = useState(false);

	const [ newsItems, setNewsItems ] = useState(null);
	const [ newsItemsError, setNewsItemsError ] = useState(null);
	const [ newsItemsLoading, setNewsItemsLoading ] = useState(false);

	const [ newsItemCreating, setNewsItemCreating ] = useState(false);
	const [ newsItemCreatingError, setNewsItemCreatingError ] = useState(null);

	const [ newsItemUpdating, setNewsItemUpdating ] = useState(false);
	const [ newsItemUpdatingError, setNewsItemUpdatingError ] = useState(null);

	const [ newsItemRemoving, setNewsItemRemoving ] = useState(false);
	const [ newsItemRemovingError, setNewsItemRemovingError ] = useState(null);

	const getNewsItem = async (newsid, refetch = false) => {
		if ((!newsItem && !newsItemError) || refetch || (newsid !== newsItem._id)) {
			setNewsItemLoading(true);

			let result = result = await Api.query(getNewsItemQuery, { _id: newsid });
			if (!result.errors) {
				setNewsItem(result.data.NewsItemById);
			} else {
				setNewsItemError(true);
			}
			setNewsItemLoading(false);
		}
	};

	const getFocusNewsItems = async (refetch = false) => {
		if ((!focusNewsItems && !focusNewsItemsError) || refetch) {
			setFocusNewsItemsLoading(true);

			let result = await Api.query(listNewsItemsQuery, { page: 1, perPage: 10, filter: { focus: true, type: 'news' }});
		
			if (!result.errors) {
				setFocusNewsItems(result.data.NewsItemPagination.items);
			} else {
				setFocusNewsItemsError(true);
			}
			setFocusNewsItemsLoading(false);
		}
	};

	const getFocusEventItem = async (refetch = false) => {
		if ((!focusEventItem && !focusEventItemError) || refetch) {
			setFocusEventItemLoading(true);

			let result = await Api.query(getDefaultNewsItemQuery, { type: 'event' });
		
			if (!result.errors) {
				setFocusEventItem(result.data.NewsItemPagination.items.length > 0 ? result.data.NewsItemPagination.items[0]: null);
			} else {
				setFocusEventItemError(true);
			}
			setFocusEventItemLoading(false);
		}
	};

	const getPageNewsItem = async (type) => {
		if ((!newsItem && !newsItemError) || (type !== newsItem.type)) {
			setNewsItemLoading(true);
			let result = null;
			result = await Api.query(getPageNewsItemQuery, { type });
			if (!result.errors) {
				setNewsItem(result.data.NewsItemPagination.items.length > 0 ? result.data.NewsItemPagination.items[0] : null);
			} else {
				setNewsItemError(true);
			}
			setNewsItemLoading(false);
		}
	}

	const getNewsItems = async (refetch = false, page = 1, pageSize = 10, filter = null) => {
		if (refetch || (!newsItems && !newsItemsError)) {
			setNewsItemsLoading(true);
			const result = await Api.query(listNewsItemsQuery, { page, perPage: pageSize, filter });
			if (!result.errors) {
				setNewsItems(result.data.NewsItemPagination.items);
				setCurrentPage(page);
				setPageSize(pageSize);
				setTotal(result.data.NewsItemPagination.count);
			} else {
				setNewsItemsError(true);
			}
			setNewsItemsLoading(false);
		}
	};

	const createNewsItem = async (newsItemToCreate) => {
		setNewsItemCreating(true);
		const result = await Api.mutate(createNewsItemQuery, { record: newsItemToCreate });
		setNewsItemCreating(false);
		if (!result.errors) {
			getNewsItems(true);
			return true;
		} else {
			return false;
		}
	};

	const updateNewsItem = async (newsItemToUpdate) => {
		setNewsItemUpdating(true);
		const result = await Api.mutate(updateNewsItemQuery, { record: newsItemToUpdate });
		setNewsItemUpdating(false);
		if (!result.errors) {
			getNewsItems(true);
			return true;
		} else {
			return false;
		}
	};

	const removeNewsItem = async (newsItemToRemove) => {
		setNewsItemRemoving(true);
		const result = await Api.mutate(removeNewsItemQuery, { _id: newsItemToRemove._id });
		setNewsItemRemoving(false);
		if (!result.errors) {
			getNewsItems(true);
			return true;
		} else {
			return false;
		}
	};

	return (
		<NewsContext.Provider
			value={{
				// item
				getPageNewsItem,
				getNewsItem,
				newsItemError,
				newsItemLoading,
				newsItem,
				// focus item
				getFocusNewsItems,
				focusNewsItemsError,
				focusNewsItemsLoading,
				focusNewsItems,
				// focus event item
				getFocusEventItem,
				focusEventItemError,
				focusEventItemLoading,
				focusEventItem,
				// items
				pageSize,
				currentPage,
				total,
				getNewsItems,
				newsItemsError,
				newsItemsLoading,
				newsItems,
				// create
				createNewsItem,
				newsItemCreating,
				newsItemCreatingError,
				// update
				updateNewsItem,
				newsItemUpdating,
				newsItemUpdatingError,
				// remove
				removeNewsItem,
				newsItemRemoving,
				newsItemRemovingError
			}}
		>
			{children}
		</NewsContext.Provider>
	);
};

export const NewsConsumer = NewsContext.Consumer;
