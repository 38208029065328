import React from 'react';
import Loader from './src/components/loader';
import { StationProvider } from './src/contexts/stationContext';
import { NewsProvider } from './src/contexts/newsContext';
import { ResponseProvider } from './src/contexts/responseContext';
import { SponsorProvider } from './src/contexts/sponsorContext';
import { EventProvider } from './src/contexts/eventContext';
import { AuthProvider } from './src/components/auth/authContext';
import Amplify from 'aws-amplify';

// eslint-disable-next-line react/display-name,react/prop-types
export default ({ element }) => {
	Amplify.configure({
		Auth: {
			mandatorySignIn: false,
			region: process.env.AWS_COGNITO_REGION,
			userPoolId: process.env.AWS_COGNITO_USER_POOL_ID,
			identityPoolId: process.env.AWS_COGNITO_IDENTITY_POOL_ID,
			userPoolWebClientId: process.env.AWS_COGNITO_WEB_CLIENT_ID
		},
		API: {
			graphql_endpoint: process.env.API_URL,
			graphql_endpoint_iam_region: process.env.AWS_COGNITO_REGION
		},
		Storage: {
			bucket: process.env.S3_IMAGES_BUCKET_ARN,
			region: process.env.S3_IMAGES_BUCKET_REGION,
			userPoolId: process.env.AWS_COGNITO_USER_POOL_ID,
			identityPoolId: process.env.AWS_COGNITO_IDENTITY_POOL_ID
		},
		Analytics: {
			disabled: true
		}
	});

	return (
		<AuthProvider loader={(fullscreen) => <Loader fullscreen={fullscreen} />}>
			<StationProvider>
				<NewsProvider>
					<ResponseProvider>
						<EventProvider>
							<SponsorProvider>{element}</SponsorProvider>
						</EventProvider>
					</ResponseProvider>
				</NewsProvider>
			</StationProvider>
		</AuthProvider>
	);
};
