import React, { useState, useEffect } from "react"
import gql from "graphql-tag"
import Api from "../api"

const getOnlineStationQuery = gql`
  query getOnlineStation ($statistics: Boolean) {
    StationByOnlineStatus (statistics: $statistics) {
      _id
      name
      phoneNumber
      slogan
      default
      logo
      whatsappNumber
      currentlisteners
      peaklisteners
      maxlisteners
      uniquelisteners
      servers
    }
  }
`

const listStationsQuery = gql`
  query listStations {
    StationMany {
      _id
      name
      ip
      phoneNumber
      whatsappNumber
      logo
      description
      shortDescription
      slogan
      default
    }
  }
`

const getStationQuery = gql`
  query getStation($_id: MongoID!) {
    StationById(_id: $_id) {
      _id
      name
      ip
      phoneNumber
      description
      shortDescription
      default
      logo
      currentlisteners
      peaklisteners
      maxlisteners
      uniquelisteners
    }
  }
`

const createStationQuery = gql`
  mutation StationCreateOne($record: CreateOneStationInput!) {
    StationCreateOne(record: $record) {
      recordId
      record {
        _id
        name
        default
      }
    }
  }
`

const updateStationQuery = gql`
  mutation StationUpdateById($record: UpdateByIdStationInput!) {
    StationUpdateById(record: $record) {
      recordId
      record {
        _id
        name
        default
      }
    }
  }
`

const removeStationQuery = gql`
  mutation StationRemoveById($_id: MongoID!) {
    StationRemoveById(_id: $_id) {
      recordId
    }
  }
`

export const StationContext = React.createContext()

export const StationProvider = ({ children }) => {
  const [station, setStation] = useState(null)
  const [stationError, setStationError] = useState(null)
  const [stationLoading, setStationLoading] = useState(false)

  const [onlineStation, setOnlineStation] = useState(null)
  const [onlineStationError, setOnlineStationError] = useState(null)
  const [onlineStationLoading, setOnlineStationLoading] = useState(false)

  const [stations, setStations] = useState(null)
  const [stationsError, setStationsError] = useState(null)
  const [stationsLoading, setStationsLoading] = useState(false)

  const [stationCreating, setStationCreating] = useState(false)
  const [stationCreatingError, setStationCreatingError] = useState(null)

  const [stationUpdating, setStationUpdating] = useState(false)
  const [stationUpdatingError, setStationUpdatingError] = useState(null)

  const [stationRemoving, setStationRemoving] = useState(false)
  const [stationRemovingError, setStationRemovingError] = useState(null)

  const getOnlineStation = async (statistics = false, refetch = false) => {
    if ((!onlineStation && !onlineStationError) || refetch) {
      setOnlineStationLoading(true)
      const result = await Api.query(getOnlineStationQuery, { statistics })
      if (!result.errors) {
        setOnlineStation(result.data.StationByOnlineStatus)
      } else {
        setOnlineStationError(true)
      }
      setOnlineStationLoading(false)
    }
  }

  const getStations = async (refetch = false) => {
    if (refetch || (!stations && !stationsError)) {
      setStationsLoading(true)
      const result = await Api.query(listStationsQuery)
      if (!result.errors) {
        setStations(result.data.StationMany)
      } else {
        setStationsError(true)
      }
      setStationsLoading(false)
    }
  }

  const getStation = async (stationId, refetch = false) => {
    if ((!station && !stationError) || refetch || station._id != stationId) {
      setStationLoading(true)
      const result = await Api.query(getStationQuery, { _id: stationId })
      if (!result.errors) {
        setStation(result.data.StationById)
      } else {
        setStationError(true)
      }
      setStationLoading(false)
    }
  }

  const createStation = async stationToCreate => {
    setStationCreating(true)
    const result = await Api.mutate(createStationQuery, {
      record: stationToCreate,
    })
    setStationCreating(false)
    if (!result.errors) {
      getStations(true)
    }
    return result
  }

  const updateStation = async stationToUpdate => {
    setStationUpdating(true)
    const result = await Api.mutate(updateStationQuery, {
      record: stationToUpdate,
    })
    setStationUpdating(false)
    if (!result.errors) {
      getStations(true)
    }
    return result
  }

  const removeStation = async stationToRemove => {
    setStationRemoving(true)
    const result = await Api.mutate(removeStationQuery, {
      _id: stationToRemove._id,
    })
    setStationRemoving(false)
    if (!result.errors) {
      getStations(true)
      return true
    } else {
      return false
    }
  }

  return (
    <StationContext.Provider
      value={{
        getStations,
        stationsError,
        stationsLoading,
        stations,
        onlineStationError,
        onlineStationLoading,
        onlineStation,
        getOnlineStation,
        getStation,
        stationError,
        stationLoading,
        station,
        // create
        createStation,
        stationCreating,
        stationCreatingError,
        // update
        updateStation,
        stationUpdating,
        stationUpdatingError,
        // remove
        removeStation,
        stationRemoving,
        stationRemovingError,
      }}
    >
      {children}
    </StationContext.Provider>
  )
}

export const StationConsumer = StationContext.Consumer
