import React, { useState } from 'react';
import Api from '../api';

import gql from 'graphql-tag';

const listResponsesQuery = gql`
	query listResponses ($page: Int!, $perPage: Int!) {
		ResponsePagination (page: $page, perPage: $perPage, sort: DATETIME_DESC) {
			count
			items {
				_id
				email
				name
				text
				dateTime
				accepted
			}
		}
	}
`;

const createResponseQuery = gql`
	mutation ResponseCreateOne($record: CreateOneResponseInput!) {
		ResponseCreateOne(record: $record) {
			recordId
			record {
				_id
			}
		}
	}
`;

const updateResponseQuery = gql`
	mutation ResponseUpdateById($record: UpdateByIdResponseInput!) {
		ResponseUpdateById(record: $record) {
			recordId
			record {
				_id
			}
		}
	}
`;

const removeResponseQuery = gql`
	mutation ResponseRemoveById($_id: MongoID!) {
		ResponseRemoveById(_id: $_id) {
			recordId
		}
	}
`;

export const ResponseContext = React.createContext();

export const ResponseProvider = ({ children }) => {
	const [ currentPage, setCurrentPage ] = useState(1);
	const [ total, setTotal ] = useState(0);
	const [ pageSize, setPageSize ] = useState(10);
  const [ responses, setResponses ] = useState(null);
	const [ responsesError, setResponsesError ] = useState(null);
	const [ responsesLoading, setResponsesLoading ] = useState(false);

	const [ responseCreating, setResponseCreating ] = useState(false);
	const [ responseCreatingError, setResponseCreatingError ] = useState(null);

	const [ responseRemoving, setResponseRemoving ] = useState(false);
	const [ responseRemovingError, setResponseRemovingError ] = useState(null);

	const [ responseAccepting, setResponseAccepting ] = useState(false);
	const [ responseAcceptingError, setResponseAcceptingError ] = useState(null);

  const getResponses = async (refetch = false, page = 1, pageSize = 10, filter = null) => {
		if (refetch || (!responses && !responsesError) || (currentPage != page && !responsesError)) {
			setResponsesLoading(true);
			const result = await Api.query(listResponsesQuery, { page, perPage: pageSize, filter });
			if (!result.errors) {
				setResponses(result.data.ResponsePagination.items);
				setCurrentPage(page);
				setPageSize(pageSize);
				setTotal(result.data.ResponsePagination.count);
			} else {
				setResponsesError(true);
			}
			setResponsesLoading(false);
		}
	};

	const createResponse = async (responseToCreate) => {
		setResponseCreating(true);
		const result = await Api.mutate(createResponseQuery, { record: responseToCreate });
		setResponseCreating(false);
		if (!result.errors) {
      getResponses(true);
      return true;
		} else {
			setResponseCreatingError(true);
			return false;
		}
	};

	const acceptResponse = async (responseToAccept) => {
		responseToAccept.accepted = true;
		setResponseAccepting(true);
		const result = await Api.mutate(updateResponseQuery, { record: responseToAccept });
		setResponseAccepting(false);
		if (!result.errors) {
      getResponses(true);
      return true;
		} else {
			setResponseAcceptingError(true);
			return false;
		}
	}

	const removeResponse = async (responseToRemove) => {
		setResponseRemoving(true);
		const result = await Api.mutate(removeResponseQuery, { _id: responseToRemove._id });
		setResponseRemoving(false);
		if (!result.errors) {
      getResponses(true);
      return true;
		} else {
			setResponseRemovingError(true);
			return false;
		}
	};

	return (
    <ResponseContext.Provider
      value={{
				pageSize,
				currentPage,
				total,
				getResponses,
        responsesError,
        responsesLoading,
				responses,
				// create
				createResponse,
				responseCreating,
				responseCreatingError,
				// remove 
				removeResponse,
				responseRemoving,
				responseRemovingError,
				// accept
				acceptResponse,
				responseAccepting,
				responseAcceptingError
      }}
    >
			{children}
		</ResponseContext.Provider>
	);
};

export const ResponseConsumer = ResponseContext.Consumer;
