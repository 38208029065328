import React, { useState } from 'react';
// import { AuthContext } from '~/components/auth/authContext';
import Api from '../api';

import gql from 'graphql-tag';

const listEventItemsQuery = gql`
	query listEventItems($page: Int!, $perPage: Int!, $filter: FilterFindManyNewsItemInput) {
		NewsItemPagination(page: $page, perPage: $perPage, sort: DATETIME_DESC, filter: $filter) {
			count
			items {
				_id
				title
				intro
				text
				image
				dateTime
				type
				tag
				focus
			} 
		}
	}
`;

const getEventItemQuery = gql`
	query getEventItem($_id: MongoID!) {
		NewsItemById(_id: $_id) {
			_id
			title
			intro
			text
			image
			dateTime
			type
			tag
			focus
		}
	}
`;

export const EventContext = React.createContext();

export const EventProvider = ({ children }) => {
	// const { auth } = useContext(AuthContext);
	const [ currentPage, setCurrentPage ] = useState(1);
	const [ total, setTotal ] = useState(0);
	const [ pageSize, setPageSize ] = useState(10);

	const [ focusEventItem, setFocusEventItem ] = useState(null);
	const [ focusEventItemError, setFocusEventItemError ] = useState(null);
	const [ focusEventItemLoading, setFocusEventItemLoading ] = useState(false);

	const [ eventItem, setEventItem ] = useState(null);
	const [ eventItemError, setEventItemError ] = useState(null);
	const [ eventItemLoading, setEventItemLoading ] = useState(false);

	const [ eventItems, setEventItems ] = useState(null);
	const [ eventItemsError, setEventItemsError ] = useState(null);
	const [ eventItemsLoading, setEventItemsLoading ] = useState(false);

	const getEventItem = async (eventid, refetch = false) => {
		if ((!eventItem && !eventItemError) || refetch || (eventid !== eventItem._id)) {
			setEventItemLoading(true);

			let result = result = await Api.query(getEventItemQuery, { _id: eventid });
			if (!result.errors) {
				setEventItem(result.data.NewsItemById);
			} else {
				setEventItemError(true);
			}
			setEventItemLoading(false);
		}
	};

	const getEventItems = async (refetch = false, page = 1, pageSize = 10, filter = null) => {
		if (refetch || (!eventItems && !eventItemsError)) {
			setEventItemsLoading(true);
			const result = await Api.query(listEventItemsQuery, { page, perPage: pageSize, filter });
			if (!result.errors) {
				setEventItems(result.data.NewsItemPagination.items);
				setCurrentPage(page);
				setPageSize(pageSize);
				setTotal(result.data.NewsItemPagination.count);
			} else {
				setEventItemsError(true);
			}
			setEventItemsLoading(false);
		}
	};

	return (
		<EventContext.Provider
			value={{
				// item
				getEventItem,
				eventItemError,
				eventItemLoading,
				eventItem,
				// items
				pageSize,
				currentPage,
				total,
				getEventItems,
				eventItemsError,
				eventItemsLoading,
				eventItems,
			}}
		>
			{children}
		</EventContext.Provider>
	);
};

export const EventConsumer = EventContext.Consumer;
