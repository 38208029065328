// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-admin-agenda-js": () => import("./../src/pages/admin/agenda.js" /* webpackChunkName: "component---src-pages-admin-agenda-js" */),
  "component---src-pages-admin-init-users-js": () => import("./../src/pages/admin/init-users.js" /* webpackChunkName: "component---src-pages-admin-init-users-js" */),
  "component---src-pages-admin-js": () => import("./../src/pages/admin.js" /* webpackChunkName: "component---src-pages-admin-js" */),
  "component---src-pages-admin-links-js": () => import("./../src/pages/admin/links.js" /* webpackChunkName: "component---src-pages-admin-links-js" */),
  "component---src-pages-admin-messages-js": () => import("./../src/pages/admin/messages.js" /* webpackChunkName: "component---src-pages-admin-messages-js" */),
  "component---src-pages-admin-responses-js": () => import("./../src/pages/admin/responses.js" /* webpackChunkName: "component---src-pages-admin-responses-js" */),
  "component---src-pages-admin-sponsor-js": () => import("./../src/pages/admin/sponsor.js" /* webpackChunkName: "component---src-pages-admin-sponsor-js" */),
  "component---src-pages-admin-stations-js": () => import("./../src/pages/admin/stations.js" /* webpackChunkName: "component---src-pages-admin-stations-js" */),
  "component---src-pages-admin-stats-js": () => import("./../src/pages/admin/stats.js" /* webpackChunkName: "component---src-pages-admin-stats-js" */),
  "component---src-pages-admin-testserver-js": () => import("./../src/pages/admin/testserver.js" /* webpackChunkName: "component---src-pages-admin-testserver-js" */),
  "component---src-pages-admin-uitleg-js": () => import("./../src/pages/admin/uitleg.js" /* webpackChunkName: "component---src-pages-admin-uitleg-js" */),
  "component---src-pages-admin-users-js": () => import("./../src/pages/admin/users.js" /* webpackChunkName: "component---src-pages-admin-users-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

