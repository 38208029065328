module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"HitstreamFm: de beste zenders onder 1 dak","short_name":"HitstreamFm","start_url":"/","background_color":"#ec207c","theme_color":"#ec207c","display":"minimal-ui","icon":"src/images/hitstream-icon.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"58648fbb68fbf865c74ee3897609a4bf"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-173617905-1"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
