import { API, Storage, graphqlOperation } from 'aws-amplify';

class Api {
	constructor() {}

	query = async (query, parameters = null) => {
		let result = null;
		try {
			if (parameters) {
				result = await API.graphql(graphqlOperation(query, parameters));
			} else {
				result = await API.graphql(graphqlOperation(query));
			}
			return result;
		} catch (e) {
			return e;
		}
	};

	mutate = async (query, input) => {
		let result = { errors: 'unknown error' };
		try {
			result = await API.graphql(graphqlOperation(query, input));
			return result;
		} catch (e) {
			return e;
		}
	};

	store = async (file, width = null, height = null) => {
		if (file) {
			const extension = file.name.split('.')[1];
			const { type: mimeType } = file;
			const key = `images/${file.uid}.${extension}`;
			try {
				const metadata = {};
				if (width) metadata.width = width;
				if (height) metadata.height = height;
				const result = await Storage.put(
					key,
					file,
					{
						contentType: mimeType,
						metadata
					}
				);
				return `https://${process.env.S3_IMAGES_BUCKET_ARN}.s3.${process.env.S3_IMAGES_BUCKET_REGION}.amazonaws.com/public/${result.key}`;
			} catch (err) {
				console.error('store error: ', err);
				return null;
			}
		}
	};
}

export default new Api();
