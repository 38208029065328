import React, { useState } from "react"
import gql from "graphql-tag"
// import { AuthContext } from '~/components/auth/authContext';
import { shuffleArray } from "../utils"
import Api from "../api"

const listSponsorsQuery = gql`
  query listSponsors {
    SponsorMany {
      _id
      name
      image
      type
      url
      active
    }
  }
`

const createSponsorQuery = gql`
  mutation SponsorCreateOne($record: CreateOneSponsorInput!) {
    SponsorCreateOne(record: $record) {
      recordId
      record {
        _id
        name
      }
    }
  }
`

const updateSponsorQuery = gql`
  mutation SponsorUpdateById($record: UpdateByIdSponsorInput!) {
    SponsorUpdateById(record: $record) {
      recordId
      record {
        _id
        name
      }
    }
  }
`

const removeSponsorQuery = gql`
  mutation SponsorRemoveById($_id: MongoID!) {
    SponsorRemoveById(_id: $_id) {
      recordId
    }
  }
`

export const SponsorContext = React.createContext()

export const SponsorProvider = ({ children }) => {
  // const { auth } = useContext(AuthContext);
  const [sponsors, setSponsors] = useState(null)
  const [sponsorsError, setSponsorsError] = useState(null)
  const [sponsorsLoading, setSponsorsLoading] = useState(false)

  const [smallSponsors, setSmallSponsors] = useState([])
  const [mediumSponsors, setMediumSponsors] = useState([])
  const [largeSponsors, setLargeSponsors] = useState([])

  const [sponsorCreating, setSponsorCreating] = useState(false)
  const [sponsorCreatingError, setSponsorCreatingError] = useState(null)

  const [sponsorUpdating, setSponsorUpdating] = useState(false)
  const [sponsorUpdatingError, setSponsorUpdatingError] = useState(null)

  const [sponsorRemoving, setSponsorRemoving] = useState(false)
  const [sponsorRemovingError, setSponsorRemovingError] = useState(null)

  const getSponsors = async (refetch = false) => {
    if (refetch || (!sponsors && !sponsorsError)) {
      setSponsorsLoading(true)
      const result = await Api.query(listSponsorsQuery)
      if (!result.errors) {
        setSponsors(result.data.SponsorMany)
        setSmallSponsors(shuffleArray(result.data.SponsorMany.filter(s => s.type === 'small')));
        setMediumSponsors(shuffleArray(result.data.SponsorMany.filter(s => s.type === 'medium')));
        setLargeSponsors(shuffleArray(result.data.SponsorMany.filter(s => s.type === 'large')));
      } else {
        setSponsorsError(true)
      }
      setSponsorsLoading(false)
    }
  }

  const createSponsor = async sponsorToCreate => {
    setSponsorCreating(true)
    setSponsorCreatingError(false)
    const result = await Api.mutate(createSponsorQuery, {
      record: sponsorToCreate,
    })
    setSponsorCreating(false)
    if (!result.errors) {
      getSponsors(true)
      return true
    } else {
      setSponsorCreatingError(true)
      return false
    }
  }

  const updateSponsor = async sponsorToUpdate => {
    setSponsorUpdating(true)
    setSponsorUpdatingError(false)
    const result = await Api.mutate(updateSponsorQuery, {
      record: sponsorToUpdate,
    })
    setSponsorUpdating(false)
    if (!result.errors) {
      getSponsors(true)
      return true
    } else {
      setSponsorUpdatingError(true)
      return false
    }
  }

  const removeSponsor = async sponsorToRemove => {
    setSponsorRemoving(true)
    setSponsorRemovingError(false)
    const result = await Api.mutate(removeSponsorQuery, {
      _id: sponsorToRemove._id,
    })
    setSponsorRemoving(false)
    if (!result.errors) {
      getSponsors(true)
      return true
    } else {
      setSponsorRemovingError(true)
      return false
    }
  }

  return (
    <SponsorContext.Provider
      value={{
        getSponsors,
        sponsorsError,
        sponsorsLoading,
        sponsors,
        smallSponsors,
        mediumSponsors,
        largeSponsors,
        // create
        createSponsor,
        sponsorCreating,
        sponsorCreatingError,
        // update
        updateSponsor,
        sponsorUpdating,
        sponsorUpdatingError,
        // remove
        removeSponsor,
        sponsorRemoving,
        sponsorRemovingError,
      }}
    >
      {children}
    </SponsorContext.Provider>
  )
}

export const SponsorConsumer = SponsorContext.Consumer
